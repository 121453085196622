// Necessary if using App Router to ensure this file runs on the client
// https://docs.datadoghq.com/real_user_monitoring/guide/monitor-your-nextjs-app-with-rum/?tab=npm#instrument-your-application
"use client";

import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: "2c1b744a-5c45-4242-a72c-72cb37b1ba02",
  clientToken: "pub9ff38d8cc8aeda7b68b08e4446dfe789",
  site: "datadoghq.com",
  service: "web",
  env: `${process.env.NEXT_PUBLIC_APP}_${
    process.env.NEXT_PUBLIC_ENV === "production"
      ? "prod"
      : process.env.NEXT_PUBLIC_ENV
  }`,
  version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || "1.0.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "allow",
  allowedTracingUrls: [
    {
      match: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
      propagatorTypes: ["tracecontext"],
    },
  ],
});

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
